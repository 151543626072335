import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';


const BookMark = (props) => {
    const[markedEvents, setMarkedEvents] = useState(localStorage.markedEvents)

    const isMarkd = (id) => {
        let local = markedEvents
        try{
            let parsed = JSON.parse(local)
            if(parsed.includes(id)){
                return(true)
            }else{
                return(false)
            }
        }catch{
            return(false)
        }
    }

    const createMark = (id) => {
        let newArray = []
        if(markedEvents === undefined){
            newArray=[id]
        }else{
            newArray = JSON.parse(markedEvents)
            if(newArray.includes(id)){
                newArray.splice(newArray.indexOf(id), 1)
            }else{
                newArray.push(id)
            }
        }
        let newMarkdEvents = JSON.stringify(Array.from(new Set(newArray)))
        setMarkedEvents(newMarkdEvents)
        localStorage.markedEvents = newMarkdEvents
    }

    return(
        <div>
        {isMarkd(props.events_id) ? 
            <Tooltip title="Снять пометку" arrow placement="right">
                <IconButton aria-label="Снять пометку" size="small" color="primary" onClick={() => createMark(props.events_id)}>
                    <BookmarkIcon/>
                </IconButton>
            </Tooltip> : 
            <Tooltip title="Пометить" arrow placement="right">
                <IconButton aria-label="Пометить" size="small" color="disabled" onClick={() => createMark(props.events_id)}>
                    <BookmarkBorderIcon/>
                </IconButton>
            </Tooltip>}
        </div>
    )
  }
  export default BookMark;
  

