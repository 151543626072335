import CheckIcon from '@mui/icons-material/Check';


const ViewHelpersTable = (props) => {
    const helpers = props.helpers.map((item, index) =>
        <tr className='tableTr' key={index}>
            <td className='tableTd'>{index + 1 + '.'}</td>
            <td className='tableTd'>{item.fio}</td>
            <td className='tableTd'>{item.tel}</td>
            <td className='tableTd center'>{item.sotr ? <CheckIcon/> : ''}</td>
        </tr>
    );
    return(
        <div>
        <div className="caption">{props.title}</div>
        <div className='predStr'>
            <table className='table'>
                <thead>
                    <tr className='tableTr'>
                        <th className='tableTh'>№</th>
                        <th className='tableTh'>ФИО</th>   
                        <th className='tableTh'>Телефон</th>  
                        <th className='tableTh'>Сотрудник</th> 
                    </tr>
                </thead>
                <tbody>
                    {helpers}
                </tbody>
            </table>
        </div>
        </div>
    )
}
    
export default ViewHelpersTable;
  

