const EventStatusSmall = (props) => {
    switch (props.workflowStep) {
        case 0:
          return(
            <span className="eventStatusSmall blue">
              Согласование
            </span>
          )
        case 1:
          return(
            <span className="eventStatusSmall blue">
              Согласование
            </span>
          )
        case 2:
          return(
            <span className="eventStatusSmall">
              Согласовано
            </span>
          )
        case 3:
          return(
            <span className="eventStatusSmall yellow">
              Согласовано
            </span>
          )
        case 5:
            return(
              <span className="eventStatusSmall">
                Выполнено
              </span>
            )
        case 4:
          return(
            <span className="eventStatusSmall red">
              Отклонено
            </span>
          )
        case 10:
          return(
            <span className="eventStatusSmall gray">
              Черновик
            </span>
          ) 
        default:
          return(
            <span className="eventStatusSmall blue">
              Согласование
            </span>
          )
      }
  }
  
  export default EventStatusSmall;