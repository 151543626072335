const EventStatus = (props) => {
    switch (props.workflowStep) {
        case 0:
          return(
            <div className="eventStatus blue">
              Отправлена на согласование заместителю директора
            </div>
          )
        case 1:
          return(
            <div className="eventStatus blue">
              Отправлена на согласование автобуса
            </div>
          )
        case 2:
          return(
            <div className="eventStatus yellow">
              Заявка согласована. Изготовление документов
            </div>
          )
        case 3:
          return(
            <div className="eventStatus yellow">
              Приказ на подписи руководителя
            </div>
          )
        case 5:
            return(
              <div className="eventStatus">
                Документы готовы
              </div>
            )
        case 4:
          return(
            <div className="eventStatus red">
              Заявка отклонена<br/>
              Коментарий: {props.comment}
            </div>
          )
        case 10:
          return(
            <div className="eventStatus gray">
              Черновик<br/>
            </div>
          )
      default:
          return(
            <div className="eventStatus blue">
              Новая
            </div>
          )
      }
  }
  
  export default EventStatus;