import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios'
import EventStatus from './views/EventStatus';
import ViewEvent from './views/ViewEvent';
import Button from '@mui/material/Button';
import Heading from './Heading';
import { useLocation,Link } from 'react-router-dom'
import EventCreater from './views/EventCreater';
import BookMark from './BookMark';


const baseUrl = '/api/'

const ViewOne = (props) => {
    const [mainInfo, setMainInfo] = useState({})
    const [studentsInfo, setStudentsInfo] = useState([])
    const [dopInfo, setDopInfo] = useState([])
    const [userId, setUserId] = useState()

    useEffect(() => {getInformation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInformation = () => {
        axios.get(baseUrl + 'events/id/' + events_id).then((res) => {
            setMainInfo(res.data[0])
            setStudentsInfo(JSON.parse(res.data[0].students))
            setDopInfo(JSON.parse(res.data[0].helpers))
        })
    }

    const showDocxButton = () => {
        if(mainInfo.workflowStep !== 10 && mainInfo.workflowStep !== 4){
            return(
                <Link to={"/api/documents/prikaz_" + mainInfo.docGUID + ".docx"} target="_blank" download>
                    <Button variant="contained" color="warning">Скачать проект приказа</Button>
                </Link>
                
            )
        }
    }

    const showDocxReqButton = () => {
        if(mainInfo.type === 'Автобус от города'){
            return(
                <Link to={"/api/documents/shablon_" + mainInfo.reqGUID + ".docx"} target="_blank" download>
                    <Button variant="contained" color="warning">Скачать заявку</Button>
                </Link>
                
            )
        }else{
            return ''
        }
    }

    const showEditButton = () => {
        if(mainInfo.usercreated === userId && (mainInfo.workflowStep === 0 || mainInfo.workflowStep === 10 || mainInfo.workflowStep === 4)){
            return(
                <Link to='/app/edit' state={{ events_id: events_id}}>
                    <Button variant="contained" color="success" >Изменить</Button>
                </Link>
                
            )
        }
    }

    const location = useLocation()
    const { events_id } = location.state

    return(
        <div >
            <Heading text={"Заявка № " + events_id} setUserId={setUserId}/>
            <div className='headBlock'>
                <div className='eventBlock'>
                    <EventStatus workflowStep={mainInfo.workflowStep} comment={mainInfo.comment}/>
                    <BookMark events_id={events_id}/>
                </div>
                <ViewEvent mainInfo={mainInfo} students={studentsInfo} helpers={dopInfo} dateInDayJs={true}/>
                <EventCreater   userCreated={mainInfo.usercreated} 
                                userEdited={mainInfo.useredited}
                                timeCreated={mainInfo.created}
                                timeEdited={mainInfo.edited}/>
                <div className='buttonRow'>
                    <Link to={-1}>
                        <Button variant="contained" color="success" >Назад</Button>
                    </Link>
                    <div className="buttonRight">
                        {showDocxReqButton()}
                        {showDocxButton()}
                        {showEditButton()}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewOne;
