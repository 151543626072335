import './App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import Heading from './components/Heading';
import axios from 'axios'
import ViewCalendarList from './components/ViewCalendarList';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers';
import { DayCalendarSkeleton } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const baseUrl = '/api/'

const AppViewCalendar = () => {
  const[eventsList, setEventsList] = useState([])
  const[datesArray, setdatesArray] = useState([])
  const[selectedDate, setSelectedDate] = useState(localStorage.selectedDate === undefined ? dayjs() : dayjs(localStorage.selectedDate))//dayjs())
  const[loadingStatus, setLoadingStatus] = useState(true)
  const[calendarView, setCalendarView] = useState(false)

  useEffect(() => {getInformation()
    localStorage.selectedDate = selectedDate
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate])

  useEffect(() => {getInformation()},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate]) 

  const getInformation = () => {
    setLoadingStatus(true)
    let date = new Date(selectedDate)
    axios.get(baseUrl + 'calendar/' + dayjs(date).format('YYYY-MM-DD')).then((res) => {
      setEventsList(res.data)
    })
    axios.get(baseUrl + 'month_count/' + dayjs(date).format('YYYY-MM-DD')).then((res) => {
        res.data.forEach((item) => {
            let save = datesArray
            save.push(item.date)
            setdatesArray(save)
        })
    })
    setLoadingStatus(false)

  }

  const addDay = () => {
    setSelectedDate(selectedDate.add(1, 'day'))
  }

  const setToday = () => {
    setSelectedDate(dayjs())
  }



  const monthChange = (props) => {
    axios.get(baseUrl + 'month_count/' + dayjs(props.$d).format('YYYY-MM-DD')).then((res) => {
        res.data.forEach((item) => {
            let save = datesArray
            save.push(item.date)
            setdatesArray(save)
        })
    })
    console.log(props.d)
  }

  const subDay = () => {
    setSelectedDate(selectedDate.subtract(1, 'day'))
  }

  const ServerDay = (props) => {
    const { datesArray = [], day, outsideCurrentMonth, ...other } = props;
    //console.log(props.day)
    //console.log(eventsCount)
    //console.log(datesArray)
    const isSelected =
      !props.outsideCurrentMonth && datesArray.indexOf(props.day.format('YYYY-MM-DD')) >= 0;
  
    return (
        isSelected ? <span className="hiLightDay"><PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}/></span> : <span className=""><PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}/></span>
        
        //isSelected ? <span className="hiLightDay"></span> : ''
        //<Badge
        //    key={props.day.toString()}
        //    color="success"
        //    overlap="circular"
        //    badgeContent={isSelected ? '' : undefined}
        //>
        //    
        //</Badge>
    );
}

  return(
    <div >
      <Heading text="Календарь"/>
      <div className='headBlock'>
        <div className="calendarPicker">
            <Tooltip title="Предыдущий день" arrow>
              <IconButton size="large" onClick={subDay}>
                  <NavigateBeforeIcon />
              </IconButton>
            </Tooltip>
            <div variant="text" onClick={() => setCalendarView(!calendarView)}>
              {dayjs(selectedDate).locale('ru').format('DD MMMM YYYY г.')}
            </div>
            <Tooltip title="Следующий день" arrow>
              <IconButton size="large" onClick={addDay}>
                  <NavigateNextIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Показать календарь" arrow>
              <IconButton size="large" onClick={() => setCalendarView(!calendarView)}>
                  <CalendarMonthIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Сегодня" arrow>
              <IconButton size="large" onClick={() => setSelectedDate(dayjs())}>
                  <CloseIcon />
              </IconButton>
            </Tooltip>
        </div>
        <div className="calendarPicker">
          {calendarView ? <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DateCalendar
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)}
                    onMonthChange={monthChange}
                    onYearChange={monthChange}
                    monthsPerRow={3}
                    //loading={true}
                    //onMonthChange={handleMonthChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                        day: ServerDay,
                    }}
                    slotProps={{
                    day: {
                        datesArray,
                    },
                    }}
                />
            </LocalizationProvider> : ''}
        </div>
        <ViewCalendarList eventsList={eventsList} buttonEdit={false} viewPage="view" loadingStatus={loadingStatus}/>
        
      </div>
    </div>
  )
}
export default AppViewCalendar;
