import './App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import Heading from './components/Heading';
import axios from 'axios'
import ViewList from './components/ViewList';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton } from '@mui/material';

const baseUrl = '/api/'

const AppViewAll = () => {
  const[eventsList, setEventsList] = useState([])
  const[page, setPage] = useState(1)
  const[loadingStatus, setLoadingStatus] = useState(true)

  useEffect(() => {getInformation()}, 
      // eslint-disable-next-line react-hooks/exhaustive-deps
  [page])

  const getInformation = () => {
    setLoadingStatus(true)
    axios.get(baseUrl + 'allevents/all?page=' + page).then((res) => {
      setEventsList(res.data)
      setLoadingStatus(false)
    })
  }

  const nextPage = (n) => {
    setPage(page + n)
  }



  return(
    <div >
      <Heading text="Все заявки"/>
      <div className='headBlock'>
        <ViewList eventsList={eventsList} buttonEdit={false} viewPage="view" loadingStatus={loadingStatus}/>
        <div className='pagination'>
          <IconButton disabled={page === 1 ? true : false} aria-label="Назад" size="small" color="primary" onClick={() => nextPage(-1)}>
            <ArrowBackIosNewIcon/>
          </IconButton>
          <span>Страница {page}</span>
          <IconButton disabled={eventsList.length <25 ? true : false} aria-label="Вперед" size="small" color="primary" onClick={() => nextPage(1)}>
            <ArrowForwardIosIcon/>
          </IconButton>
        </div>
      </div>

    </div>
  )
}
export default AppViewAll;
