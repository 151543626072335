import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios'
import EventStatus from './views/EventStatus';
import ViewEvent from './views/ViewEvent';
import Button from '@mui/material/Button';
import Heading from './Heading';
import { useLocation,Link,useNavigate } from 'react-router-dom'
import EventCreater from './views/EventCreater';
import BookMark from './BookMark';

const baseUrl = '/api/'

const AppEditBus = (props) => {
    const navigate = useNavigate();
    const [mainInfo, setMainInfo] = useState({})
    const [studentsInfo, setStudentsInfo] = useState([])
    const [dopInfo, setDopInfo] = useState([])

    const accept = () => {
        const object = {id: events_id,
                        result: true,
                        comment: ''}
        axios.post('/api/edit/coordbus/' + events_id, object).then((result) => {
            if(result.status === 200){
                alert('Заявка согласована')
                navigate("/app/coordzam")
            }
        })
    }

    const decline = () => {
        const comment = prompt("Укажите причину отказа");
        const object = {id: events_id,
                        result: false,
                        comment: comment}
        axios.post('/api/edit/coordbus/' + events_id, object).then((result) => {
            if(result.status === 200){
                alert('Заявка отклонена')
                navigate("/app/coordzam")
            }
        })
    }

    const showDocxButton = () => {
        return(
            <Link to={"/api/documents/prikaz_" + mainInfo.docGUID + ".docx"} target="_blank" download>
                <Button variant="contained" color="warning">Скачать проект приказа</Button>
            </Link>
            
        )
    }

    const showDocxReqButton = () => {
        if(mainInfo.type === 'Автобус от города'){
            return(
                <Link to={"/api/documents/shablon_" + mainInfo.reqGUID + ".docx"} target="_blank" download>
                    <Button variant="contained" color="warning">Скачать заявку</Button>
                </Link>
                
            )
        }else{
            return ''
        }
    }

    const showAcceptButton = () => {
        if(mainInfo.workflowStep === 1){
            return(
                <Button variant="contained" color="success" onClick={() => accept()}>Согласовать</Button>
            )
        }else{
            return('')
        }
    }

    const showDeclineButton = () => {
        if(mainInfo.workflowStep === 0 || mainInfo.workflowStep === 1){
            return(
                <Button variant="contained" color="error" onClick={() => decline()} >Отклонить</Button>
            )
        }else{
            return('')
        }
    }



    useEffect(() => {getInformation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInformation = () => {
        axios.get(baseUrl + 'events/id/' + events_id).then((res) => {
            setMainInfo(res.data[0])
            setStudentsInfo(JSON.parse(res.data[0].students))
            setDopInfo(JSON.parse(res.data[0].helpers))
        })
    }

    const location = useLocation()
    const { events_id } = location.state

    return(
        <div >
            <Heading text={"Заявка № " + events_id}/>
            <div className='headBlock'>
                <div className='eventBlock'>
                    <EventStatus workflowStep={mainInfo.workflowStep} comment={mainInfo.comment}/>
                    <BookMark events_id={events_id}/>
                </div>
                <ViewEvent mainInfo={mainInfo} students={studentsInfo} helpers={dopInfo} dateInDayJs={true}/>
                <EventCreater   userCreated={mainInfo.usercreated} 
                                userEdited={mainInfo.useredited}
                                timeCreated={mainInfo.created}
                                timeEdited={mainInfo.edited}/>

                <div className='buttonRow'>
                    <Link to={-1}>
                        <Button variant="contained" color="success" >Назад</Button>
                    </Link>
                    <div className="buttonRight">
                        {showDocxButton()}
                        {showDocxReqButton()}
                        {showDeclineButton()}
                        {showAcceptButton()}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppEditBus;
