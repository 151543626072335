import React from 'react';
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Link } from "react-router-dom";
import EventCreaterOne from './views/EventCreaterOne';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Tooltip } from '@mui/material';

const baseUrl = '/api/'

const ViewCalendarList = (props) => {
    let events = <div></div>
    const[markedEvents, setMarkedEvents] = useState(localStorage.markedEvents)
    const[userData, setUserData] = useState({})
    useEffect(() => {
        axios.get(baseUrl + 'users/').then((res) => {
            setUserData(res.data)
        })
      }, [])
    
    const isMarkd = (id) => {
        let local = markedEvents
        try{
            let parsed = JSON.parse(local)
            if(parsed.includes(id)){
                return(true)
            }else{
                return(false)
            }
        }catch{
            return(false)
        }
    }

    const createMark = (id) => {
        let newArray = []
        if(markedEvents === undefined){
            newArray=[id]
        }else{
            newArray = JSON.parse(markedEvents)
            if(newArray.includes(id)){
                newArray.splice(newArray.indexOf(id), 1)
            }else{
                newArray.push(id)
            }
        }
        let newMarkdEvents = JSON.stringify(Array.from(new Set(newArray)))
        setMarkedEvents(newMarkdEvents)
        localStorage.markedEvents = newMarkdEvents
    }

    const classesList = (classes) => {
        return(
            classes.map((item) => {
                return(
                    <div>
                        {item[0]}: {item[1]} {num_word(item[1], ['учащийся', 'учащихся', 'учащихся'])}
                    </div>
                )
            })
        )
    }

    const heplersCount = (item) => {
        let count = 1 + item.helpers.length + (item.secondFio !== '' ? 1 : 0)
        return(
            <div>
                {count} {num_word(count, ['работник', 'работника', 'работников'])}
            </div>
        )
    }

    const num_word = (value, words) => {  
        value = Math.abs(value) % 100; 
        var num = value % 10;
        if(value > 10 && value < 20) return words[2]; 
        if(num > 1 && num < 5) return words[1];
        if(num === 1) return words[0]; 
        return words[2];
    }

    const loadSkeleton = () => {
        return(
            <div className="outString c__five__view">
                <span className='idDate skeleton'><Skeleton variant="rounded" height={50}/></span>
                <span className='skeleton'><Skeleton variant="rounded" height={50}/></span>
                <span className='skeleton'><Skeleton variant="rounded" height={50}/></span>
                <span className='skeleton'><Skeleton variant="rounded" height={50}/></span>
                <span className='skeleton'><Skeleton variant="rounded" height={50}/></span>
                <span className='skeleton'><Skeleton variant="rounded" height={50}/></span>
            </div>
        )
    }

    if(props.eventsList.length > 0){
        events = props.eventsList.map((item) =>
            <div className="outString c__five__view" key={item.id}>
                <span className='idDate'> ⓘ {item.id}
                    <span className='modalInfo'>
                        Заявка № {item.id} от {dayjs(item.created).format('DD.MM.YYYY')}<br/>{<EventCreaterOne userCreated={userData[item.usercreated]} />}
                    </span>
                </span>
                
                <span className=''>
                    <div>
                        {dayjs(item.dateStart).format('DD.MM.YYYY')}
                    </div>
                    <div>
                        с {item.timeStart} до {item.timeEnd}
                    </div>
                </span>
                <span className=''>
                    {item.eventName} 
                </span>
                <span className=''>{item.type}</span>
                <span>
                    {heplersCount(item)}
                    {classesList(item.classes)}
                </span>
                <span className=''>
                    <Tooltip title="Посмотреть" arrow placement="right">
                        <Link to={'/app/' + props.viewPage} state={{ events_id: item.id }}>
                            <IconButton aria-label="delete" size="small" color="primary" >
                                <VisibilityIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    {props.buttonEdit && (item.workflowStep === 0 || item.workflowStep === 4 || item.workflowStep === 10) ? <Link to='/app/edit' state={{ events_id: item.id }}>
                        <IconButton aria-label="delete" size="small" color="primary" >
                            <EditIcon />
                        </IconButton>
                    </Link> : ''}
                    {isMarkd(item.id) ? 
                    <Tooltip title="Снять пометку" arrow placement="right">
                        <IconButton aria-label="Снять пометку" size="small" color="primary" onClick={() => createMark(item.id)}>
                            <BookmarkIcon/>
                        </IconButton>
                    </Tooltip> : 
                    <Tooltip title="Пометить" arrow placement="right">
                        <IconButton aria-label="Пометить" size="small" color="disabled" onClick={() => createMark(item.id)}>
                            <BookmarkBorderIcon/>
                        </IconButton>
                    </Tooltip>}
                </span>
            </div>
        )
    }
  
    return(
    <div >
        <div className="outString c__five__view" >
            <span className="outString__head">Ид</span>
            <span className="outString__head">Время</span>
            <span className="outString__head">Название мероприятия</span>
            <span className="outString__head">Тип</span>
            <span className="outString__head">Классы</span>
            <span className="outString__head">Действия</span>
        </div>
        {props.loadingStatus ?  loadSkeleton() : events}
        {!props.loadingStatus && !props.eventsList.length > 0 ? <div>Активных заявок не найдено</div> : ''}
        <div className='buttonRow'>
            
        </div> 
    </div>
  )
}
export default ViewCalendarList;
