import axios from 'axios'
import { useEffect, useState } from 'react';
import Moment from 'react-moment';

const baseUrl = '/api/'

const EventCreater = (props) => {
    //console.log('111' + props.userCreated)
    const[createdData, setCreatedData] = useState({})
    const[editedData, setEditedData] = useState({})
    useEffect(() => {
        if(props.userCreated){
            axios.get(baseUrl + 'user/' + props.userCreated).then((res) => {
                setCreatedData(res.data)
            })
        }
      }, [props.userCreated])
    useEffect(() => {
        if(props.userEdited){
            axios.get(baseUrl + 'user/' + props.userEdited).then((res) => {
                setEditedData(res.data)
            })
        }
    }, [props.userEdited])
    return(
        <div className='userInfo'>
            <div>Создано: <Moment date={props.timeCreated} format="DD.MM в HH:mm"/> {createdData.name}</div>
            <div>Изменено: <Moment date={props.timeEdited} format="DD.MM в HH:mm"/> {editedData.name} </div>
        </div>
    )
  }
  
  export default EventCreater;