import './App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import Heading from './components/Heading';
import axios from 'axios'
import ViewList from './components/ViewList';

const baseUrl = '/api/'

const AppViewCoordBus = () => {
  const[eventsList, setEventsList] = useState([])
  const[loadingStatus, setLoadingStatus] = useState(true)

  useEffect(() => {getInformation()}, [])

  const getInformation = () => {
    setLoadingStatus(true)

    axios.get(baseUrl + 'bus_events/').then((res) => {
      setEventsList(res.data)
      setLoadingStatus(false)

    })
  }
  return(
    <div >
      <Heading text="Согласование автобуса"/>
      <div className='headBlock'>
        <ViewList eventsList={eventsList} buttonEdit={false} bus={true} viewPage="editb" loadingStatus={loadingStatus}/>
      </div>
    </div>
  )
}
export default AppViewCoordBus;
