import Button from '@mui/material/Button';
import ViewEvent from './views/ViewEvent';

const PreView = (props) => {
    const handleBack = () => {
        props.handleSetStep(3)
    }

    const handleSend = () => {
        props.sendData()
    } 

    const handleDraft = () => {
        props.sendDraft()
    } 

    return(
        <div className='headBlock'>
            <ViewEvent mainInfo={props.mainInfo} students={props.studentsInfo} helpers={props.dopInfo} />
            <div className='buttonRow'>
                <Button variant="contained" color="success" onClick={handleBack}>Назад</Button>
                {props.draftView ? <Button variant="contained" color="warning" onClick={handleDraft}>{props.saveDraft}</Button> :''}
                <Button variant="contained" color="success" onClick={handleSend}>{props.saveText}</Button>
            </div>
        </div>
    )
}
export default PreView;
