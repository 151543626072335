const EventCreaterOne = (props) => {
    try {
        return(
            <span>{props.userCreated.name}</span>
        )
      } catch (error) {
        return(
            <span></span>
        )
      }
  }
  
  export default EventCreaterOne;