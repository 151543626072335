import { useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputMask from "react-input-mask";
import { Checkbox } from "@mui/material";
import { useEffect } from "react";
import {FormControlLabel} from "@mui/material";

const AddPeople = (props) => {
    const [fio, setFio] = useState('')
    const [tel, setTel] = useState('')
    const [Sotr, setSotr] = useState(false)
    const [fioCheck, setFioCheck] = useState(true)
    const [telCheck, setTelCheck] = useState(true)
    const [addCheck, setAddCheck] = useState(false)

    useEffect(() => {
      /^[А-Яа-яЁёЙй]+[ ][А-Яа-яЁёЙй]+([ ][А-Яа-яЁёЙй]+)?([ ][А-Яа-яЁёЙй]+)?$/.test(fio) ? setFioCheck(true) : setFioCheck(false);
      /^[+][7][(][9][0-9]{2}[)][0-9]{3}[-][0-9]{2}[-][0-9]{2}$/.test(tel) ? setTelCheck(true) : setTelCheck(false)
    },[fio, tel])

    const add = () => {
      console.log(Sotr)
      setAddCheck(true)
      if(fioCheck && telCheck){
        props.addToDopInfo(fio, tel, Sotr)
        setFio('')
        setTel('')
        setAddCheck(false)
      }
    }

    return(
      <div >
        <div className='inpRow'>
          <div className="gr threecheck">
            <TextField  fullWidth id="outlined-basic" label="Фамилия Имя Отчество"
                        variant="outlined" value={fio}
                        error={!fioCheck && addCheck}
                        helperText={!fioCheck && addCheck ? 'Введите корректное ФИО без сокращений' : ''}
                        onChange={(event) => setFio(event.target.value)}/>
            <InputMask
                mask='+7(999)999-99-99'
                value={tel}
                onChange={(event) => setTel(event.target.value)}
                disabled={false}
                maskChar=" ">
                    {() => <TextField label="Телефон" error={!telCheck && addCheck}
                    helperText={!telCheck && addCheck ? 'Введите корректный телефон' : ''}/>}
            </InputMask>
            <FormControlLabel control={<Checkbox value={Sotr} onChange={(event) => setSotr(event.target.checked)}/>} label="Сотрудник"  />

          </div>
        </div>
        <div className='inpRow'>
            <Button variant="contained" color="success" 
              onClick={add}>
                Добавить
            </Button>
        </div>

      </div>
    )
  }
  export default AddPeople
  