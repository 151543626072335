import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddPeople from './AddPeople';
import EditIcon from '@mui/icons-material/Edit';


const AddStep3 = (props) => {

    const handleBack = () => {
        props.handleSetStep(2)
    }
    
    const handleNext= () => {
        props.handleSetStep(4)
    }

    const handleFirst= () => {
      props.handleSetStep(1)
  }

    const mainInfoSrting = () => {
      return(<div className='outString c__five_1' key="_1">
                <span>1.</span>
                <span>{props.mainInfo.mainFio}</span>
                <span>{props.mainInfo.mainFioTel}</span>
                <span>Руководитель</span>
                <span>
                  <IconButton aria-label="delete" size="small" color="primary" onClick={handleFirst}>
                    <EditIcon />
                  </IconButton>
                </span>
            </div>
      )
    }

    const secondInfoSrting = () => {
      if(props.mainInfo.secondFio !== ''){
        return(<div className='outString c__five_1' key="_2">
                  <span>2.</span>
                  <span>{props.mainInfo.secondFio}</span>
                  <span>{props.mainInfo.secondFioTel}</span>
                  <span>Заместитель</span>
                  <span>
                    <IconButton aria-label="delete" size="small" color="primary" onClick={handleFirst}>
                      <EditIcon />
                    </IconButton>
                  </span>
              </div>
        )
      }else{
        return ''
      }
    }

    const helpers = () => {
        //console.log(props.dopInfo, props.mainInfo)
        const cnt = props.mainInfo.secondFio !== '' ? 3 : 2
        if(props.dopInfo.length > 0){
          return(props.dopInfo.map((item, index) =>
            <div className='outString c__five_1' key={index}>
                <span>{index + cnt + '.'}</span>
                <span>{item.fio}</span>
                <span>{item.tel}</span>
                <span>{item.sotr ? 'Сотрудник' : ''}</span>
                <span>
                    <IconButton aria-label="delete" size="small" color="primary" onClick={() => props.delFromDopInfo(item)}>
                        <DeleteIcon />
                    </IconButton>
                </span>
            </div>
          ))
        }else{
          return('')
        }
      }


  return(
    <div className='headBlock'>
        <div className="caption">Информация о сопровождающих</div>
            <div className='formGrid'>
              <div className='inpRow'>
                {mainInfoSrting()}
                {secondInfoSrting()}
                <span></span>
                {helpers()}
              </div>
              <div className="caption">Добавить сопровождающего</div>
              <div className='inpRow'>
                <AddPeople addToDopInfo={props.addToDopInfo}/>
              </div>
              <div className='buttonRow'>
                <Button variant="contained" color="success" onClick={handleBack}>Назад</Button>
                <Button variant="contained" color="success" onClick={handleNext}>Предпросмотр</Button>
              </div>
            </div>
      </div>
  )
}
export default AddStep3;
